export const button = {
    baseStyle: {        
        
        px: 20,
        _focus: { 
            boxShadow: 'none' 
        },
        colorScheme: 'brand',   
        transition: "all 0.3s ease-in"             
    },
    sizes:{
        sm: {
            px: "20px",
            py: "20px"
        },
        toolButton: {
            px: "10px",
            py: "5px",
            fontSize: "12px",
            rounded: "md"
        },
        xs: {
            px: "20px",
            py: "15px"
        },
        action:{
            py: 2,
            px: 3.5,
            rounded: '3px',
            fontSize: "13px",            
            
        }
    },
    variants:{
        link: {
            _hover:{
                textDecoration: 'none'
            }
        },
        subtle:({colorScheme}) => {            
            return({
                mx:1,
                px:3,
                py:2,
                bg: `${colorScheme+'.50'}`,
                _loading:{
                    bg: `${colorScheme+'.100'}`,
                    _hover:{
                        bg: `${colorScheme+'.100'}`,
                    }
                },
                color: `${colorScheme+'.500'}`,
                _hover:{
                    bg: `${colorScheme+'.100'}`,
                    color: `${colorScheme+'.700'}`,
                }
            })
        }     
    },
    defaultProps: {
        colorScheme: 'brand'        
    },
}