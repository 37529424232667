import React from 'react'
import { chakra, shouldForwardProp, useToken } from "@chakra-ui/react"

const IndicatorSvg = React.forwardRef(({variant, ...props}, ref) => { 
    const [color1, color2, color3, color4] = useToken('colors',['brand.500', 'green.500', 'yellow.500', 'red.500'])

    if(variant === "typing"){
        return(
            <svg  viewBox="0 0 100 50"  {...props} ref={ref}>
                <circle cx="24.5" cy="0.4263" r="6" fill={color1 || "#e15b64"}>
                    <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="35.5;20;35.5;35.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.5s"/>
                </circle>
                <circle cx="41.5" cy="32.5" r="6" fill={color2 || "#f47e60"}>
                    <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="35.5;20;35.5;35.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.375s"/>
                </circle>
                <circle cx="58.5" cy="62.5" r="6" fill={color3 || "#f8b26a"}>
                    <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="35.5;20;35.5;35.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.25s"/>
                </circle>
                <circle cx="75.5" cy="62.5" r="6" fill={color4 || "#abbd81"}>
                    <animate attributeName="cy" calcMode="spline" keySplines="0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5" repeatCount="indefinite" values="35.5;20;35.5;35.5" keyTimes="0;0.25;0.5;1" dur="1s" begin="-0.125s"/>
                </circle>
            </svg>
        )
    }

    if(variant === 'flip'){
        return(
            <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"   {...props} ref={ref}>
                <g transform="translate(50 50)">
                    <g>
                        <animateTransform attributeName="transform" type="rotate" calcMode="discrete" values="0;90;180;270;360" keyTimes="0;0.25;0.5;0.75;1" dur="1.075268817204301s" repeatCount="indefinite"></animateTransform>
                        <path d="M-40 0A40 40 0 1 0 40 0" fill="#479ffb">
                            <animate attributeName="fill" calcMode="discrete" values="#479ffb;#47cc99;#ffaf10;#e53e3e;#479ffb" keyTimes="0;0.24;0.49;0.74;0.99" dur="1.075268817204301s" repeatCount="indefinite"></animate>
                        </path>
                        <path d="M-40 0A40 40 0 0 1 40 0" fill="#47cc99">
                            <animate attributeName="fill" calcMode="discrete" values="#47cc99;#ffaf10;#e53e3e;#479ffb;#47cc99" keyTimes="0;0.25;0.5;0.75;1" dur="1.075268817204301s" repeatCount="indefinite"></animate>
                        </path>
                        <path d="M-39 0L39 0" stroke="#056edd" strokeWidth="2">
                            <animate attributeName="stroke" values="#479ffb;#056edd;#2a976d;#47cc99;#2a976d;#be7e00;#ffaf10;#be7e00;#b41818;#e53e3e;#b41818;#056edd;#479ffb" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="1.075268817204301s" repeatCount="indefinite"></animate>
                        </path>
                        <g>
                        <path d="M-40 0A40 40 0 0 1 40 0Z" fill="#056edd">
                            <animate attributeName="fill" values="#479ffb;#056edd;#2a976d;#47cc99;#2a976d;#be7e00;#ffaf10;#be7e00;#b41818;#e53e3e;#b41818;#056edd;#479ffb" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="1.075268817204301s" repeatCount="indefinite"></animate>
                            <animateTransform attributeName="transform" type="scale" values="1 1;1 0;1 -1;1 1" keyTimes="0;0.5;0.999;1" dur="0.26881720430107525s" repeatCount="indefinite"></animateTransform>
                        </path>
                    </g>
                </g>
            </g>
        </svg>
        )
    }
    
    return(
    // <svg viewBox="0 0 120 20" {...props} ref={ref}>
    //     <circle cx="15" cy="15" r="15">
    //         <animate attributeName="r" from="15" to="15"
    //                 begin="0s" dur="0.8s"
    //                 values="15;9;15" calcMode="linear"
    //                 repeatCount="indefinite" />
    //         <animate attributeName="fillOpacity" from="1" to="1"
    //                 begin="0s" dur="0.8s"
    //                 values="1;.5;1" calcMode="linear"
    //                 repeatCount="indefinite" />
    //     </circle>
    //     <circle cx="60" cy="15" r="9" fillOpacity="0.3">
    //         <animate attributeName="r" from="9" to="9"
    //                 begin="0s" dur="0.8s"
    //                 values="9;15;9" calcMode="linear"
    //                 repeatCount="indefinite" />
    //         <animate attributeName="fillOpacity" from="0.5" to="0.5"
    //                 begin="0s" dur="0.8s"
    //                 values=".5;1;.5" calcMode="linear"
    //                 repeatCount="indefinite" />
    //     </circle>
    //     <circle cx="105" cy="15" r="15">
    //         <animate attributeName="r" from="15" to="15"
    //                 begin="0s" dur="0.8s"
    //                 values="15;9;15" calcMode="linear"
    //                 repeatCount="indefinite" />
    //         <animate attributeName="fillOpacity" from="1" to="1"
    //                 begin="0s" dur="0.8s"
    //                 values="1;.5;1" calcMode="linear"
    //                 repeatCount="indefinite" />
    //     </circle>
    // </svg>
        <svg viewBox="0 0 100 50"  {...props} ref={ref}>
            <g transform="translate(10 25)">
                <circle cx="0" cy="0" r="10" fill={color1 || "#e15b64"}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
            </g>
            <g transform="translate(30 25)">
                <circle cx="0" cy="0" r="10" fill={color2 || "#f8b26a"}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
            </g>
            <g transform="translate(50 25)">
                <circle cx="0" cy="0" r="10" fill={color3 || "#abbd81"}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
            </g>
            <g transform="translate(70 25)">
                <circle cx="0" cy="0" r="10" fill={color4 || "#81a3bd"}>
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
            </g>
        </svg>
    )
})


const Indicator = chakra(IndicatorSvg, {
    shouldForwardProp: (prop) => {
            const isChakraProp = !shouldForwardProp(prop)
            if (isChakraProp) return false
            return ["name", "onClick", "variant"].includes(prop)
        },
    baseStyle: (props) => {                
        return({
            fill: props.color || 'yellow.500',
            w: "80px",
            h: "35px",       
            ...(props.size === 'md' && { w: "80px", h:"35px"}),
            ...(props.size === 'sm' && { w: "48px", h:"22px"}),
            ...(props.size === 'lg' && { w: "150px", h:"65px"})                
        })
    }    
})
    
export {Indicator}