export const badge ={
    baseStyle: {        
        rounded: '2px',        
        px: 1.5,   
        py:0.5,             
        transition: "all 0.3s ease-in",
        textTransform: 'capitalize',
        fontWeight: "500",
        fontSize: "10px",
        letterSpacing: "0.15px"
    },
    sizes:{
              
    },
    variants:{

    },
    defaultProps:{        
        variant: "subtle"
    }
}