import {requestTimeout, handleResponse} from '../utils'
import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

const cookie = new Cookies();

export const userApi = {
    _all,
    _create,    
    _update,
    _delete
};

async function _all() {

    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'GET',
        credentials: "include",        
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users', requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })                
        
}

async function _create(form_data){
        
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    
    const requestOptions = {
        
        method: 'POST',
        credentials: "include",   
        headers: {
            'X-XSRF-TOKEN': xsrf_token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: form_data
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users', requestOptions))
                .then(handleResponse)
                .then( (data) => {                                        
                    return data;
                });    
}


async function _update(form_data, id){
        
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    
    const requestOptions = {
        
        method: 'POST',
        credentials: "include",   
        headers: {
            'X-XSRF-TOKEN': xsrf_token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: form_data
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+id, requestOptions))
                .then(handleResponse)
                .then( (data) => {                                        
                    return data;
                });    
}


async function _delete(id){
    
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))            
    const requestOptions = {
        method: 'DELETE',     
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'users/'+id, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })  
}
