export const select = {    
    baseStyle:{
        backgroundColor: 'white',
    },
    variants: {
        outline: {
            rounded: "sm",
            field:{
                bg: 'white',
                rounded:"sm",
                _focus: {
                    // bg: 'gray.50',
                    borderColor: 'gray.700',
                    shadow: 'none'
                },
                _hover:{
                    borderColor: 'gray.500',
                }
            }
        },
        flushed:{
            field:{
                rouned: 'sm',                
                shadow: 'none',
                borderBottomWidth: "1px",
                _focus: {
                    // bg: 'gray.50',
                    borderColor: 'brand.500',
                    shadow: 'none'
                },
            }
        }       
    },
    defaultProps: {
        // focusBorderColor: 'brand.700',
    }
}

