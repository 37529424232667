import React from 'react'
import { 
    Modal as ChakraModal, ModalBody, ModalContent, 
    ModalOverlay, ModalCloseButton, ModalHeader, 
    ModalFooter 
} from '@chakra-ui/react'


const Modal = (props) => {

    const modalRef= React.createRef()
    let {open, size, onClose, bg, transition, closeable=true, footer, title, placement, ...rest} = props
    return(
        <ChakraModal 
            isOpen={open || false} 
            size={size || "lg"}  
            onClose={onClose} 
            // motionPreset={transition || "slideInRight"}
            motionPreset="scale"            
            autoFocus={false}
            initialFocusRef={modalRef}
            placement={placement || "right"}
            blockScrollOnMount={false}
            {...rest}            
            >
            <ModalOverlay />
            <ModalContent>
                {
                    title &&
                    <ModalHeader px="7" fontSize="18px" fontWeight="700" color="gray.600" py="10px" borderBottom="1px solid" borderColor="gray.200" bg="white">{title}</ModalHeader>
                }
                
                {
                    closeable && <ModalCloseButton rounded="full" _focus={{ outline: "none" }} />
                }
                
                <ModalBody p={props.p}   bg={bg || 'white'}>
                    {props.children}
                </ModalBody>
                {
                    footer &&
                    <ModalFooter borderTop="1px solid" borderColor="gray.200" bg="gray.50">
                        {footer}
                    </ModalFooter>
                }
                
                <div ref={modalRef}/>
            </ModalContent>    
        </ChakraModal>
    )
}

export {Modal}