import { useContext, useRef, useEffect } from 'react';
import {AppContext, AlertContext} from '../services'


const useApp = () => useContext(AppContext);
const useAlert =() => useContext(AlertContext)


const  usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}


export {useApp, useAlert, usePrevious}
export * from './useToast'
export * from './useLocalStorage'
//export * from './useCategories'
export * from './useDebounce'