import {requestTimeout, handleResponse} from '../utils'
import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

const cookie = new Cookies();
var base_uri = "form-settings"

export const formSettingsApi = {
    _get,
    _details,
    _add,
    _delete,
    _update,  
    _get_campaigns,     
    _get_clients,
    _get_projects,
    _create_client,
    _create_project
};


async function _get(offset,filters,size) {

    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'GET',     
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+"/"+offset+"/"+size, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                })  

    
  }


  async function _details(uid) {

    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'GET',     
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + "forms/details/"+uid, requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return response
                }) 
    
  }
  
async function _add(form_data) {
      // console.log("add api data",form_data);
    
      const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

      const requestOptions = {
          method: 'POST',     
          credentials: "include",   
          headers: {            
              'X-XSRF-TOKEN': xsrf_token,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          body: JSON.stringify(form_data)
      };
      return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri, requestOptions))                    
                  .then(handleResponse)
                  .then((response) => {    
                      return response
                  }) 
    
}


async function _update(form_data, id){
    

    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

      const requestOptions = {
          method: 'PUT',     
          credentials: "include",   
          headers: {            
              'X-XSRF-TOKEN': xsrf_token,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          },
          body: JSON.stringify(form_data)
      };
      return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+"/"+id, requestOptions))                    
                  .then(handleResponse)
                  .then((response) => {    
                      return response
                  }) 
   
}


async function _delete(id){
    
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

      const requestOptions = {
          method: 'DELETE',     
          credentials: "include",   
          headers: {            
              'X-XSRF-TOKEN': xsrf_token,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
      };
      return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+'/'+id, requestOptions))                    
                  .then(handleResponse)
                  .then((response) => {    
                      return response
                  }) 
    

}

async function _get_campaigns(){
    
    const requestOptions = {
        method: 'GET',               
        headers: {                        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };

    let uri = process.env.REACT_APP_CRM_API +"get-campaigns?apiKey="+process.env.REACT_APP_CRM_API_KEY
    return requestTimeout(apiMaxTime,fetch(uri, requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })    
}

async function _get_clients(){
    
    const requestOptions = {
        method: 'GET',               
        headers: {                        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };

    let uri = process.env.REACT_APP_CRM_API +"get-clients?apiKey="+process.env.REACT_APP_CRM_API_KEY
    return requestTimeout(apiMaxTime,fetch(uri, requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })    
}

async function _get_projects(){
    
    const requestOptions = {
        method: 'GET',               
        headers: {                        
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };

    let uri = process.env.REACT_APP_CRM_API +"get-projects?apiKey="+process.env.REACT_APP_CRM_API_KEY
    return requestTimeout(apiMaxTime,fetch(uri, requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })    
}

async function _create_client(data){
    
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    
    
    const requestOptions = {
        method: 'POST',               
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data)       
    };

    console.log("Data", data)

    // return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+'/'+id, requestOptions))                    
    // let uri = process.env.REACT_APP_CRM_API +"createClient?apiKey="+process.env.REACT_APP_CRM_API_KEY
    // console.log("Uri", uri)
    return requestTimeout(apiMaxTime,fetch(apiUrl+'crm-add-client', requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })    
}

async function _create_project(data){
    
    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'POST',         
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(data)       
    };

    // let uri = process.env.REACT_APP_CRM_API +"createProject?apiKey="+process.env.REACT_APP_CRM_API_KEY
    // console.log("Uri", uri)
    return requestTimeout(apiMaxTime,fetch(apiUrl+'crm-add-project', requestOptions))                    
                .then(handleResponse)
                .then((data) => {    
                    return data
                })    
}
