export const number_input = {    
    parts: ['field'],
    variants: {       
        flushed:{
            field:{
                rouned: 'sm',                
                shadow: 'none',
                borderBottomWidth: "1px",
                _focus: {
                    // bg: 'gray.50',
                    borderColor: 'brand.500',
                    shadow: 'none'
                },
                _placeholder:{
                    color: 'gray.300'
                },
                _disabled: {
                    color: 'gray.300',
                    _hover:{
                        cursor: 'not-allowed'
                    }

                }
            }
        },
        payslip:{
            field:{
                rounded: "md",
                bg: "white",
                shadow: "none",
                borderWidth: 1,
                borderColor: "gray.200"
            }
        }
    },
    defaultProps: {
        // focusBorderColor: 'brand.700',
    }
}

