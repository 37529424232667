export const tag = {   
    // parts: ['label'],
    variants:{
        "ACTIVE":{
            container:{              
                bg: "green.100",
                fontSize: '10px',
                color: 'green.700'
            },            
        },
        "INACTIVE":{
            container:{              
                bg: "red.100",
                fontSize: '10px',
                color: 'red.700'
            },            
        }
    }
}