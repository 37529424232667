import React from 'react'
import {
    FormControl,
    FormLabel,
    Input as ChakraInput, InputLeftAddon,
    Textarea, Text, InputGroup, InputLeftElement,
    FormErrorMessage,
    InputRightElement,
  } from "@chakra-ui/react"
import { Icon } from '.'
  
const Input = React.forwardRef( ({error, icon, info, leftAddon,  rightAddon, clearable, required=false, onClear,  size, label, textarea = false, ...props}, ref) => {

    return(
        <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.500" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} m="0" fontSize="xs" color="red">*</Text>}</FormLabel>}
            {
                info &&
                <Text mt="0"   color="gray.500" fontSize="sm">{info}</Text>
            }
            <InputGroup size={size || 'md'} position="relative">
                {
                    icon &&
                    <InputLeftElement>
                        <Icon name={icon} fontSize="sm" mt="-2" color="gray.300" />
                    </InputLeftElement>
                }
                {
                    leftAddon &&
                    <InputLeftAddon bg="transparent" p={0} border={0}>
                        {leftAddon}
                    </InputLeftAddon>
                }
                { textarea ? <Textarea  {...props} ref={ref} /> :  <ChakraInput  {...props} ref={ref}  />}            
                {
                    (clearable && props.value) &&
                    <InputRightElement onClick={() => onClear()}>
                        <Icon  color="gray.400" cursor="pointer" _hover={{ color: "gray.500" }} name="close-circle-fill" fontSize="15px" position="relative" />
                    </InputRightElement>
                }
                {
                    rightAddon && <InputRightElement>{rightAddon}</InputRightElement>
                }
                

            </InputGroup>
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
})
export {Input}