export const textarea = {    
    baseStyle:{
        backgroundColor: 'white',
    },
    variants: {        
        flushed:{
            rouned: 'sm',                
            shadow: 'none',
            borderBottomWidth: "1px",
            _focus: {
                // bg: 'gray.50',
                borderColor: 'brand.500',
                shadow: 'none'
            }        
        }
    }    
}

