import { ServiceConstants as Constants } from '../constants'


const initialState = {
    services: [],
    total: 0,    
};

export function serviceReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                services: action?.payload?.services,
                total: action?.payload?.total                
            };
                          
       
        case Constants.FAILED:
            return{
                ...state                
            };
        

        case Constants.ADD:
            return {
                ...state,
                services: [...state?.services, action?.payload?.service]
            };
                          
        case Constants.UPDATE:
                let index = state.services.findIndex((item) => parseInt(item.id) === parseInt(action.payload.service.id));
                let itemsArray = [...state.services];
                if(index > -1)
                    itemsArray[index] = action.payload.service
                return {
                    ...state,               
                    services: itemsArray
                };           
    
        case Constants.DELETE:            
                return{
                    ...state,
                    services: state.services.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
                }
                           
        case Constants.CLEAR:
            return{
                ...state,
                services: [],
                total: 0,                
            }
        default:
            return state
    }
}