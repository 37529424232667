import {requestTimeout, handleResponse} from '../utils'
import { Cookies } from 'react-cookie'
// import axios from 'axios'


const apiUrl = process.env.REACT_APP_API_URL
const appUrl = process.env.REACT_APP_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

const cookie = new Cookies();

export const authApi = {
    _login,
    _logout,
    _register,
    _forget_password,
    _check_reset,
    _reset_password,
    _set_csrf,
    _me
};


async function _logout(){
            
    const requestOptions = {
        
        method: 'GET',
        credentials: "include",   
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'logout', requestOptions))
                .then(handleResponse)
                .then( () => {                                        
                    return true;
                });    
}

async function _login(form_data) {

    let auth_data = {
        ...form_data        
    }        
    // const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'POST',
        credentials: "include",        
        headers: {            
            // 'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify(auth_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'login', requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return {user: response.user, token: response.token};                                        
                })                
        
}


async function _register(form_data) {        
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'register', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                
    
    
}

async function _forget_password(form_data) {    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'forget-password', requestOptions))
        .then(handleResponse)
        .then((response) => {
            return response
        })        
    
}

async function _reset_password(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'reset-password', requestOptions))
                .then(handleResponse)
                .then((response) => {
                    return response
                })                
    
}

async function _check_reset(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'check-reset', requestOptions))    
                .then(handleResponse)
                .then((response) => {
                
                    return response
                })

    
}

async function _set_csrf(){
    const requestOptions = {
        method: 'GET',
        credentials: "include"
    };
    return await fetch(appUrl+'sanctum/csrf-cookie', requestOptions)
    
}

async function _me() {

    const xsrf_token = decodeURIComponent(cookie.get('XSRF-TOKEN'))    

    const requestOptions = {
        method: 'GET',     
        credentials: "include",   
        headers: {            
            'X-XSRF-TOKEN': xsrf_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'me', requestOptions))                    
                .then(handleResponse)
                .then((response) => {    
                    return {user: response.user}
                })  
                    
  }
  