import { BrandConstants as Constants } from '../constants'


const initialState = {
    brands: [],
    total: 0
};

export function brandReducer(state = initialState, action) {
    switch (action.type) {  

        case Constants.GET:
            return {
                ...state,
                brands: action?.payload?.brands,
                total: action?.payload?.total                
            };

        case Constants.ADD:
            return {
                ...state,
                brands: [...state?.brands, action?.payload?.brand]
            };
                          
        case Constants.UPDATE:
                let index = state.brands.findIndex((item) => parseInt(item.id) === parseInt(action.payload.brand.id));
                let itemsArray = [...state.brands];
                if(index > -1)
                    itemsArray[index] = action.payload.brand
                return {
                    ...state,               
                    brands: itemsArray
                };           
    
        case Constants.DELETE:            
                return{
                    ...state,
                    brands: state.brands.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
                }
                       
        case Constants.FAILED:
            return{
                ...state                
            };
        
                           
        case Constants.CLEAR:
            return{
                ...state,
                brands: [],
                total: 0,                
            }
        default:
            return state
    }
}