export const colors = {
    brand: {
        50: "#F4FFFE",
        100: "#D6E4FF",
        200: "#ADC8FF",
        300: "#84A9FF",
        400: "#6690FF",
        500: "#3366FF",
        600: "#254EDB",
        700: "#1939B7",
        800: "#102693",
        900: "#091A7A",
    },
    info: {
        50: "#EEFFFE",
        100: "#D7FFFD",
        200: "#AFFBFF",
        300: "#87F1FF",
        400: "#69E4FF",
        500: "#38CDFF",
        600: "#28A1DB",
        700: "#1C7AB7",
        800: "#115793",
        900: "#0A3E7A",
    },
    warning: {
        50: "#FFFBF0",
        100: "#FFF6DA",
        200: "#FFEAB6",
        300: "#FFDC91",
        400: "#FFCD76",
        500: "#FFB649",
        600: "#DB9135",
        700: "#B77024",
        800: "#935217",
        900: "#7A3D0E",
    },
    error:{
        50: "#FFF4EF",
        100: "#FFE5D8",
        200: "#FFC6B2",
        300: "#FF9F8B",
        400: "#FF7B6F",
        500: "#FF3F3F",
        600: "#DB2E3D",
        700: "#B71F3A",
        800: "#931435",
        900: "#7A0C32"
    },
    accent:{        
        50: "#FEF3ED",
        100: "#FCDBC9",
        200: "#F9AF95",
        300: "#EF785F",
        400: "#DF4637",
        500: "#CB0000",
        600: "#AE000F",
        700: "#920019",
        800: "#75001E",
        900: "#610021",
    },
    success:{
        50: "#F5FDEF",
        100: "#E7FCD9",
        200: "#C9FAB5",
        300: "#A2F28D",
        400: "#7DE66E",
        500: "#47D642",
        600: "#30B837",
        700: "#219A31",
        800: "#157C2B",
        900: "#0C6628",
    },

    sidebar: '#122960',
    
    flat:{            
        tomato: "#FB4630",
        pink: "#FE445F",
        purple: "#773DE3",
        indigo: "#BDA5FF",
        navy: "#3C50BC",
        blue: "#A2DFFF",
        aqua: "#00C3ED",
        teal: "#009988",
        green: "#77C33E",
        lime: "#A8E172",
        yellow: "#FFE200",
        orange: "#FFB718",
        gray: "#616C78"
    },
    muted:{            
        tomato: "rgba(251,70,48, 0.25)",
        pink: "rgba(254,68,95, 0.25)",
        purple: "rgba(119,61,227, 0.25)",
        indigo: "rgba(178,141,240, 0.25)",
        navy: "rgba(60,80,188, 0.25)",
        blue: "rgba(51,137,225, 0.25)",
        aqua: "rgba(0,195,237, 0.25)",
        teal: "rgba(0,189,153, 0.25)",
        green: "rgba(119,195,62, 0.25)",
        lime: "rgba(168,225,114, 0.25)",
        yellow: "rgba(255,204,52, 0.25)",
        orange: "rgba(255,183,24, 0.25)",
        gray: "rgba(97,108,120, 0.25)",
    }      
}