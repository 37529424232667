export const text = {     
    baseStyle: {
        color: 'gray.600',
        fontWeight: '400',
    },  
    variants:{
        "lead":{
            mb: 3,
            fontSize: "18px"
        },
        "price": {
            my: 1,
            fontSize: "14px",
            fontWeight: "600",
            color: "brand.500"
        },
        "link":{
            cursor: "pointer",                        
            color: 'blue.500',  
            fontSize: "15px",
            fontWeight: "600",
            _hover:{
                color: 'blue.700'
            }       
        },
        "gray":{
            color: 'gray.600'
        },
        "dim":{
            color: 'gray.400'
        },
        "label":{
            fontSize: "14px",
            fontWeight: "600",
            color: "gray.800"
        },
        "label_value":{
            fontSize: "14px",
            fontWeight: "400",            
        }
    },
    sizes: {
        "sm":{
            fontSize: "15px"
        }
    }
}