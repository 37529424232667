import React from "react"
import { IconButton } from "@chakra-ui/react"
import { Icon } from "../../@uikit"
import { useNavigate } from "react-router-dom"

export const BackButton = ({...rest}) => {
    const navigate = useNavigate()
    return(
        <IconButton 
            onClick={() => navigate(-1)}
            size="sm"
            variant="ghost"
            rounded="full"
            icon={<Icon name="arrow-left-line" color="currentcolor" />}            
        />
    )
}