import React, { useState, useEffect } from 'react'
import { HStack, Box, Text,
    FormControl, FormLabel, IconButton, InputLeftElement,
    InputGroup, Input, InputRightElement, useBoolean,
    Popover, FormErrorMessage,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,    
    useOutsideClick,
} from '@chakra-ui/react'

import { Icon } from '.'



const Option = ({label, value, isActive,  onChange, ...props}) => {

    return(
        <Box color={isActive ? "brand.600" : "gray.700"} onClick={() => onChange(value)} bg={isActive ? "brand.50" : "transparent"} cursor="pointer" px="6" py="2" _hover={{ bg: 'brand.50' }} {...props}>
            <HStack justifyContent="space-between" alignItems="center">
                <Text  color="currentcolor" fontWeight="500" fontSize="14px">{label}</Text>
                {isActive && <Icon color="currentcolor" name="md-checkmark" /> }
            </HStack>
        </Box>
    )
} 


const MultiSelect = React.forwardRef( ({children,  info,  error, placeholder, label, required, onChange, value ,  ...rest}, ref) => {

    const [ current, setCurrent ] = useState(value)
    const [ innerValue, setInnerValue ] = useState([])    
    const [ show, setShow ] = useBoolean(false)
    const [ q, setQ] = useState('')
    const [ options, setOptions ] = useState([])
    const popupRef = React.useRef()
    const inputRef = React.useRef()

    
    useEffect(() => {
        setCurrent(value)
    }, [value])


    useEffect(() => {                
        if(children?.length > 0){            
            let opts = []    
            React.Children?.map(children, (child, i) => {
                if(React.isValidElement(child)){
                    if(current?.includes(child?.props?.value) && !innerValue?.includes(child?.props?.label) ){                        
                        setInnerValue([...innerValue, child?.props?.label])                        
                    }
                    let cloned_opt = React.cloneElement(child, {               
                            isActive: current?.includes(child?.props?.value), 
                            value: child?.props?.value,
                            key: i,
                            onChange: (i) => {                                
                                if(current?.includes(child?.props?.value)){                                    
                                    setCurrent([...current?.filter(x => x !== child?.props?.value)])
                                    onChange([ ...current?.filter(x => x !== child?.props?.value)])
                                    setInnerValue([ ...innerValue?.filter(x => x !== child?.props?.label)])
                                }
                                else{
                                    setCurrent([...current, child?.props?.value])
                                    onChange([...current, child?.props?.value])
                                }                                
                                // setShow.off()
                            } 
                    });
                    return opts.push(cloned_opt)
                }
                return 
            })         
            setOptions(opts)
        }

    }, [children, onChange, setShow, current, innerValue])

    const clearVal = () => {
        setCurrent([])
        onChange([])        
        setInnerValue([])
    }

    const onFilter = (value) => {     
        
        setQ(value)   
        let opts = [];
        React.Children.forEach(children, (child, i) => {                  
            if(child?.props?.label?.toUpperCase().includes(value?.toUpperCase()) || value===""  ){
                let cloned_opt = React.cloneElement(child, {               
                        // isActive: current === child?.props?.value, 
                        isActive: current?.includes(child?.props?.value), 
                        value: child?.props?.value,
                        key: i,
                        onChange: (i) => {                            
                            // setCurrent([...current, child?.props?.value])
                            // onChange([...current, child?.props?.value])
                            // setInnerValue([...innerValue, child?.props?.label])
                            if(current?.includes(child?.props?.value)){                                    
                                setCurrent([...current?.filter(x => x !== child?.props?.value)])
                                onChange([ ...current?.filter(x => x !== child?.props?.value)])
                                setInnerValue([ ...innerValue?.filter(x => x !== child?.props?.label)])
                            }
                            else{
                                setCurrent([...current, child?.props?.value])
                                onChange([...current, child?.props?.value])
                            }                                
                            // setQ('')
                            // setShow.off()
                        } 
                });
                opts.push(cloned_opt)
            }
            
        })
        setOptions(opts)
    }
    
    useOutsideClick({
        ref: popupRef,
        handler: () => setShow.off(),
    })


    let all_options = options;
    

    const formatInnerValue = (arrayVal) => {
        let new_val = [...arrayVal];
        if(arrayVal?.length > 2){
            new_val.length=2            
            new_val.push('...')            
        }
        return new_val?.join(', ')
    }    
    return(
        <Box m="0">
            <FormControl isInvalid={error}>
                {label && <FormLabel  fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} { required && <Text as="span" fontSize="sm" color="red">*</Text>}</FormLabel>}
                {info && <Text mb="4" color="gray.500" fontSize="sm">{info}</Text> }
                <Box  ref={popupRef}>
                    <Popover initialFocusRef={inputRef} isOpen={show} matchWidth offset={0} _focus={{ shadow:'none' }}> 
                        <PopoverTrigger>                            
                            <InputGroup ref={ref}>
                                <Input 
                                    autoComplete="off" 
                                    placeholder={placeholder} 
                                    onFocus={setShow.on}  
                                    readOnly                                    
                                    value = {formatInnerValue(innerValue || [])}
                                    {...rest} 
                                    ref={inputRef} 
                                />
                                <InputRightElement>
                                    <HStack>                                
                                        {
                                            (innerValue?.length > 0) &&
                                            <IconButton 
                                                onClick={() => clearVal()} 
                                                rounded="full" 
                                                variant="ghost" 
                                                colorScheme="gray" 
                                                size="xs" 
                                                icon={<Icon fontSize="18px" name="ios-close" color="currentcolor" />} 
                                            />
                                        }
                                        <Icon fontSize="18px" onClick={setShow.on} name="md-arrow-dropdown" />
                                    </HStack>
                                </InputRightElement>
                            </InputGroup>
                        </PopoverTrigger>
                        <PopoverContent w="100%" shadow="none" _focusVisible={false} _focus={{ shadow:'none' }} rounded="none">
                            <PopoverBody shadow="none" p="0">                                
                                <Box borderBottom="1px solid" borderColor="gray.200">
                                    <InputGroup>
                                        <InputLeftElement>
                                            <Icon name="ios-search" fontSize="sm" mt="0" color="gray.300" />
                                        </InputLeftElement>
                                        <Input onChange={(e) => onFilter(e.target.value)} value={q} size="sm" placeholder="Filter..." borderWidth="0" px={3} py={2} variant="unstyled"  />
                                    </InputGroup>
                                </Box>                                                                
                                <Box maxH="180px" overflowY="auto">
                                    {all_options}
                                </Box>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>  
                </Box>
                <FormErrorMessage>{error}</FormErrorMessage>              
            </FormControl>
        </Box>     
    )
})

MultiSelect.Option = Option
export { MultiSelect }