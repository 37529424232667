export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    ME: 'AUTH_PROFILE',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    CLEAR: 'AUTH_CLEAR',
    LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED'
}

export const OrderConstants = {
    GET: 'GET_ORDERS',
    DETAILS: 'GET_ORDER_DETAILS',
    ADD: 'CREATE_ORDER',
    UPDATE: 'UPDATE_ORDER',
    DELETE: 'DELETE_ORDER',
    FAILED: 'FAILED_ORDER',    
    CLEAR: 'CLEAR_ORDERS'
}

export const BrandConstants = {
    GET: 'GET_BRANDS',    
    ADD: 'CREATE_BRAND',
    UPDATE: 'UPDATE_BRAND',
    DELETE: 'DELETE_BRAND',
    FAILED: 'FAILED_BRAND',    
    CLEAR: 'CLEAR_BRANDS'
}

export const CustomerConstants = {
    GET: 'GET_CUSTOMERS',    
    DETAILS: 'GET_CUSTOMER_DETAILS',
    ADD: 'CREATE_CUSTOMER',
    UPDATE: 'UPDATE_CUSTOMER',
    DELETE: 'DELETE_CUSTOMER',
    FAILED: 'FAILED_CUSTOMER',    
    CLEAR: 'CLEAR_CUSTOMERS'
}

export const ServiceConstants = {
    GET: 'GET_SERVICES',        
    ADD: 'CREATE_SERVICE',
    UPDATE: 'UPDATE_SERVICE',
    DELETE: 'DELETE_SERVICE',
    FAILED: 'FAILED_SERVICE',    
    CLEAR: 'CLEAR_SERVICES'
}

export const UserConstants = {
    GET: 'GET_USERS',        
    ADD: 'CREATE_USER',
    UPDATE: 'UPDATE_USER',
    DELETE: 'DELETE_USER',
    FAILED: 'FAILED_USER',    
    CLEAR: 'CLEAR_USERS'
}

export const FormConstants = {
    GET: 'GET_FORMS',        
    ADD: 'CREATE_FORMS',
    UPDATE: 'UPDATE_FORMS',
    DELETE: 'DELETE_FORMS',
    FAILED: 'FAILED_FORMS',    
    CLEAR: 'CLEAR_FORMS',
    LOADING: 'FORMS_LOADING',   
}



