import React, { createContext, useState } from 'react';

export const GlobalContext = createContext({
  itemslayout: [],
  setItems: () => {},
  componentType: "",
  setComponentType: () => {},
});

export const GlobalContextProvider = (props) => {
  const [itemslayout, setItems] = useState([]);
  const [componentType, setComponentType] = useState("");

  return (
    <GlobalContext.Provider value={{ itemslayout, setItems, componentType, setComponentType }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
