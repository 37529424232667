import React from 'react';
import { Switch as ChakraSwitch, Box, FormControl, Text } from "@chakra-ui/react"

const Switch = ({ label, info, required, ...props }) => {
  return (
    <FormControl>
      <label>
        <Box my="0.5" display="flex" alignItems="flex-start">
          <ChakraSwitch
            isFocusable={false}
            size="sm"
            colorScheme="brand"
            defaultChecked={props.checked} // Set the default checked state
            {...props}
            mt="1"
            mr="3"
          />
          <Box>
            {label && (
              <Text fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">
                {label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}
              </Text>
            )}
            {info && <Text mb="4" color="gray.500" fontSize="sm">{info}</Text>}
          </Box>
        </Box>
      </label>
    </FormControl>
  );
}

export { Switch };
