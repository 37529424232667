import { extendTheme } from "@chakra-ui/react"
import { button, colors, custom, headings, badge, input, textarea, text, toast, select, number_input, alert_style, tag, tooltip } from "./styles"

export const theme = extendTheme({
    styles:{
        global: custom
    },
    fonts: {
        heading: `'Poppins', sans-serif`,
        body: `'Poppins', sans-serif`,
    },
    breakpoints: {
        sm: "320px",
        md: "768px",
        lg: "960px",
        xl: "1200px",
    },
    colors: colors,
    shadows:{
        custom: "0px 0px 20px 0px rgba(50, 50, 50, 0.15)",
        medium: "0px 0px 20px 0px rgba(50, 50, 50, 0.10)",
    },
    components:{
        Badge: badge,
        Heading: headings,
        Input: input,
        Textarea: textarea,
        Button: button,
        Text: text,
        toast: toast,
        Select: select,
        NumberInput: number_input,
        Alert: alert_style,
        Tag: tag,
        Tooltip: tooltip
    }
})