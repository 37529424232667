import React from "react"
import { Helmet } from "react-helmet-async"


export const MetaHeader=({title, description, ...rest}) => {
    return(
        <Helmet>
            <title>{title} - {process.env.REACT_APP_NAME}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}