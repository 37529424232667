export const input = {    
    baseStyle:{
        backgroundColor: 'white',
    },
    variants: {
        outline: {
            rounded: "md",
            field:{
                bg: 'white',
                rounded:"md",
                // borderWidth: 1,
                borderColor: "gray.400",
                _focus: {
                    // bg: 'gray.50',
                    borderColor: 'brand.500',
                    // shadow: 'none'
                },
                _hover:{
                    // borderColor: 'brand.500',
                }                
            }
        },
        flushed:{
            field:{
                rouned: 'sm',                
                shadow: 'none',
                borderBottomWidth: "1px",
                _focus: {
                    // bg: 'gray.50',
                    borderColor: 'brand.500',
                    shadow: 'none'
                },
                _disabled: {
                    color: 'gray.300'
                }
            }
        },
        search:{
            field:{
                rounded: "md",
                shadow: "none",
                borderWidth: 0,
                bg: "gray.100",
                _focus: {
                    bg: 'gray.200',                    
                    shadow: 'none'
                },

            }
        },
        plain:{
            field:{
                rounded: "md",
                shadow: "none",
                borderWidth: 0,
                bg: "transparent",
                _focus: {
                    bg: "transparent",
                    shadow: 'none'
                },

            }
        },
        payslip:{
            field:{
                rounded: "md",
                bg: "white",
                shadow: "none",
                borderWidth: 1,
                borderColor: "gray.200"
            }
        }


    },
    defaultProps: {
        // focusBorderColor: 'brand.700',
    }
}

