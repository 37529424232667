export const datepicker = {    
    ".react-calendar": {         
        maxW: "100%",
        bg: "white",
        borderColor: "gray.400",
        ".react-calendar__navigation":{
            minW: "44px",
            bg: "none", 
            display: "flex",
            p: "10px",
            flex: 1,
            justifyContent: "space-between",
            button:{
                fontSize: "18px",
                px: 2,
                "&.react-calendar__navigation__label":{
                    fontSize: "14px"
                },
                "&:[disabled]":{
                    color: "#f0f0f0"
                },
                // "&:(not([disabled]):hover":{
                //     bg: "#f8f8fa"
                // }
            }
        },
        "abbr[title]":{
            textDecoration: "none"
        },
        ".react-calendar__month-view__weekdays":{
            py: 3,
            div:{
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: "12px",
                color: "gray.400"

            }
        },
        ".react-calendar__tile":{     
            transition: "all 0.3s ease-in",       
            fontSize: "14px",
            "&[disabled]":{
                cursor: 'not-allowed',
                opacity: '0.4',
                "&:hover":{
                    bg: "transparent !important",
                    abbr:{
                        bg: "transparent"
                    }
                }
            },
            "&.react-calendar__tile--active abbr":{
                bg: "brand.500",                
                color: "white",
                "&:hover":{
                    bg: "brand.600",                    
                }
            },
            "&.react-calendar__tile--now abbr":{
                border: "2px solid",
                borderColor: 'brand.500',
                color: "brand.500",                
            },
            "&.react-calendar__tile--active.react-calendar__tile--now abbr":{
                color: "white"
            },
            "&:hover abbr":{
                bg: "gray.200"                
            },
            "abbr":{
                transition: "all 0.2s ease-in",       
                m: "4px",
                p: "4px",                               
               display: 'block',
               rounded: "md",
            },
            "&.react-calendar__month-view__days__day--neighboringMonth abbr":{
                color: "gray.300"
            },
            "&.react-calendar__year-view__months__month":{
                py: 3,
                abbr:{
                    py: 2,
                },
                "&.react-calendar__tile--hasActive abbr":{
                    bg: "brand.500",
                    color: "white",
                    "&:hover":{
                        bg: "brand.600",                    
                    }
                }
            },
            "&.react-calendar__decade-view__years__year":{
                py:3,
                abbr:{
                    py: 2,
                },
                "&.react-calendar__tile--hasActive":{
                    abbr:{
                        bg: "brand.500",
                        color: "white",
                        m: "4px",
                        p: "4px",                               
                        display: 'block',
                        rounded: "md",
                    },
                    "&:hover abbr":{
                        bg: "brand.600",                    
                    }
                }
            }

        },
        ".react-calendar__month-view__days":{
            
        }
    }
}