import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import appReducer from './reducers'

const storageKey = process.env.REACT_APP_STORAGE_KEY
const initialState = {};

const persistConfig = {
    key: storageKey,
    storage,
    whitelist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, appReducer)


const AppStore = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    initialState: initialState   
});
let persistor = persistStore(AppStore)
export { AppStore, persistor };