import React from 'react'
import { Box, Image, VStack, Heading, Text, Button } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useLocation } from 'react-router-dom'

const valid_routes = ['/', '/forget-password']

export const NotFound = () => {
    const navigate = useNavigate()
    const {pathname} = useLocation()

    
    
    return(
        <Box pos="fixed" overflow="hidden" left="0" top="0" w="100%" h="100vh" bg="brand.900" display="flex" justifyContent="center" alignItems="center">
            <Box overflowY="auto" w="100%" display="flex" justifyContent="center" alignItems="center">
                <Helmet>
                    <title>404 Page Not Found - { process.env.REACT_APP_NAME }</title>
                </Helmet>
                {
                    valid_routes.includes(pathname) ?
                    <VStack spacing="5">
                        <Image src="/assets/images/404.svg" maxW="300px" h="auto" />
                        <Heading m="0" fontSize="7rem" color="white">Oops!</Heading>
                        <Heading color="white" fontWeight="normal" m="0" size="md" textTransform="uppercase">403 You are not authorized</Heading>
                        <Box my="10" w="480px" textAlign="center">
                            <Text color="white">The page you are looking for might have been removed, had its name changed or is temporarily unavailble.</Text>
                        </Box>
                        <Button onClick={() => navigate('/')} mt="4" color="brand.500" _hover={{ bg: 'gray.100' }} variant="action" rounded="full" bg="white">Go To Home Page</Button>
                    </VStack>
                    :
                    <VStack spacing="5">
                        <Image src="/assets/images/404.svg" maxW="300px" h="auto" />
                        <Heading m="0" fontSize="7rem" color="white">Oops!</Heading>
                        <Heading color="white" fontWeight="normal" m="0" size="md" textTransform="uppercase">404 Page Not Found</Heading>
                        <Box my="10" w="480px" textAlign="center">
                            <Text color="white">The page you are looking for might have been removed, had its name changed or is temporarily unavailble.</Text>
                        </Box>
                        <Button onClick={() => navigate('/')} mt="4"  rounded="full" >Go To Home Page</Button>
                    </VStack>
                }
            </Box>
        </Box>
    )
}

