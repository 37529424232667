import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authAction } from '../redux/actions'
import { authApi } from '../api'
import { useBoolean } from '@chakra-ui/react'
import { Cookies } from 'react-cookie'

const cookie = new Cookies();
const accessToken = process.env.REACT_APP_ACCESS_TOKEN

const initialState = {
    user: null,   
    token: localStorage.getItem(accessToken)
};

const AppContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),     
    logout: () => Promise.resolve(),
});

export const AppProvider = (props) => {
    
    const auth = useSelector(state => state.auth);        
    const [state, setState] = useState({...initialState})    
    const [ mount, setMount] = useBoolean()
    const [ loading, setLoading ] = useBoolean()    

    const dispatch = useDispatch()

    
    const login = (form_data) => {

        return new Promise(async (resolve, reject) => {
          
            dispatch(authAction.login(form_data))
                .then((response) => {  
                    // setCookie(process.env.REACT_APP_AUTH_COOKIE, response?.token, { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax' })                  
                    resolve(response) 
                })
                .catch((error) => reject(error) )
            })

    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            dispatch(authAction.logout())        
                .then(() => {                        
                    resolve()
                })
                .catch((e) => {
                    reject()
                })
        })

    }

    const setCsrfCookie = async() => {
        let check = cookie.get('XSRF-TOKEN') || null;   
        // console.log("Check", check)
        if(!check){
            await authApi._set_csrf()
        }            
    }
    

    useEffect(() => {
        const init = async () => {   
            setLoading.on()  
            
            dispatch(authAction.me())
                .then((res) => {
                    setMount.on()
                    setLoading.off()
                })
                .catch((err) => {
                    dispatch(authAction.clear())                        
                    setMount.on()            
                    setLoading.off()                    
                })
                
            // }else{
            //     removeCookie(process.env.REACT_APP_AUTH_COOKIE, true, { path: '/', expires: getAuthCookieExpiration(), sameSite: 'lax' })
            //     await dispatch(authAction.logout())
            //     setMount.on()            
            //     setLoading.off()
            // }
            // try{
                
            //     await dispatch(authAction.auth_check())                
            //     setMount.on()
            //     setLoading.off()
            // }
            // catch(err){                                
            //     console.log("Auth Error", err)
            //     setMount.on()
            //     setLoading.off()
            // }
        }
        if(!mount && !loading){
            init()                  
        }
        

    }, [mount, setMount, state, loading, setState, setLoading, dispatch])

    
    
    return (
        <AppContext.Provider
            value={{
                ...state,
                ...auth,
                loading,
                setLoading,                
                login,
                logout
            }}>
            {props.children}
            
        </AppContext.Provider>
    );
}

export  {AppContext}