import { AuthConstants as Constants } from '../constants'


const initialState = {
    user: null,
    loggedIn: false
};

export function authReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.LOGIN:
            return {
                ...state,
                loggedIn: true,
                token: action?.payload?.token,
                user: action?.payload?.user,
            };
                  
        case Constants.ME:
            return{
                ...state,
                user: action?.payload?.user,
            };
       
        case Constants.LOGOUT:
        case Constants.LOGOUT_FAILED:
            return{
                ...state,
                loggedIn: false,                
                user: null,
            };
        
                   
        case Constants.FAILED:
            return{
                ...state
            }
        case Constants.CLEAR:
            return{
                ...state,
                user: null
            }
        default:
            return state
    }
}