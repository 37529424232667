import { CustomerConstants as Constants } from '../constants'


const initialState = {
    customers: [],
    total: 0,
    customer: null    
};

export function customerReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                customers: action?.payload?.customers,
                total: action?.payload?.total                
            };
                  
        case Constants.DETAILS:
            return{
                ...state,
                customer: action.payload.customer,
            };
       
        case Constants.ADD:
            return {
                ...state,
                customers: [...state?.customers, action?.payload?.customer]
            };
                              
        case Constants.UPDATE:
                let index = state.customers.findIndex((item) => parseInt(item.id) === parseInt(action.payload.customer.id));
                let itemsArray = [...state.customers];
                if(index > -1)
                    itemsArray[index] = action.payload.customer
                return {
                    ...state,               
                    customers: itemsArray
                };           
        
        case Constants.DELETE:            
                return{
                    ...state,
                    customers: state.brands.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
                }
                
        case Constants.FAILED:
            return{
                ...state                
            };
        
                           
        case Constants.CLEAR:
            return{
                ...state,
                customers: [],
                total: 0,
                customer: null
            }
        default:
            return state
    }
}