import { FormConstants as Constants } from '../constants'
import { formSettingsApi as api } from '../../api';

export const formSettingAction = {
    get,
    add,
    update,
    remove,
    clear,
};

function get(offset, filters, size) {    
    return dispatch => {
        dispatch(loading())
        return new Promise((resolve, reject) => {
         
            api._get(offset, filters, size)
                .then(
                    payload => {
                       // console.log('GET action payload:', payload); // Log the payload
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function loading() { return { type: Constants.LOADING } }
    function failure(error) { return { type: Constants.FAIL, error } }
}



function add(form_data) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success() { return { type: Constants.UPDATE } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function remove(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.DELETE, payload } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function clear() {
    
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}