import React from 'react'
import { Box, Image, Heading, Text } from '@chakra-ui/react'

const NoData = ({size, title, flex, error, text, visible, children,  ...rest}) => {

    if(visible)
    {
        return(        
            <Box display="flex" flex="1" flexDir="column"  p="2" alignItems="center" justifyContent="center">
                <Image src="/images/no-data.svg" width={size === "sm" ? "60px" : "150px"} h="auto" alt={title} title={title} />
                <Heading mt="5" mb="0" fontWeight="700"  color="gray.700" fontSize={size === "sm" ? "14px" : "20px"}>{title}</Heading>
                {
                    error ?
                    <Box rounded="full" bg="red.500" px={2} py={0.5} mt={3}>
                        <Text fontSize={size === "sm" ? "12px" : "14px"} color="white">{error}</Text>
                    </Box>
                    :
                    <Text mt={2} fontSize={size === "sm" ? "12px" : "14px"} color="gray.500">{text}</Text>    
                }                
                {children}
            </Box>
        )
    }
    return(<></>)
}

export { NoData }