import { OrderConstants as Constants } from '../constants'


const initialState = {
    orders: [],
    total: 0,
    order: null    
};

export function orderReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                orders: action?.payload?.orders,
                total: action?.payload?.total                
            };
                  
        case Constants.DETAILS:
            return{
                ...state,
                order: action.payload.order,
            };
       
        case Constants.FAILED:
            return{
                ...state                
            };
        

        case Constants.ADD:
            return {
                ...state,
                orders: [...state?.orders, action?.payload?.order]
            };
                          
        case Constants.UPDATE:
                let index = state.orders.findIndex((item) => parseInt(item.id) === parseInt(action.payload.order.id));
                let itemsArray = [...state.orders];
                if(index > -1)
                    itemsArray[index] = action.payload.order
                return {
                    ...state,               
                    orders: itemsArray
                };           
    
        case Constants.DELETE:            
                return{
                    ...state,
                    orders: state.orders.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
                }
                           
        case Constants.CLEAR:
            return{
                ...state,
                orders: [],
                total: 0,
                order: null
            }
        default:
            return state
    }
}