import { FormConstants as Constants } from '../constants'

const initialState = {
    form_settings: [],    
    error: null,    
    loading: false   
};

export function formSettingReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET: // Update the case to match the action type
    //    console.log("action", action.payload?.form_settings)
        return {
            ...state,
            form_settings:  action.payload?.form_settings,
            loading: false,
            error: null,
          };
          
        case Constants.ADD:
            return {
                ...state,
                form_settings: [...state?.form_settings,  action.payload?.form_settings],
                loading: false,
                error: null,
            }

        case Constants.UPDATE:            
            const index = state.form_settings.findIndex(x => parseInt(x.id) ===   parseInt(action.payload?.form_settings?.id))
            const newArray = [...state.form_settings];
            if(index > -1)
                newArray[index] = action.payload?.form_settings
            return {
                ...state,
                form_settings: newArray,
                loading: false,
                error: null,
            }   

        case Constants.DELETE:            
            return{
                ...state,
                form_settings: state?.form_settings?.filter(x => parseInt(x?.id) !== parseInt(action?.payload?.id)),
                loading: false,
                error: null,
            };

        
        
        case Constants.FAIL:
            return{
                ...state,
                error: action?.error,
                loading: false
            }
        
        case Constants.LOADING:
            return{
                ...state,
                error: null,
                loading: true
            }

        case Constants.CLEAR:
        default:
            return state
    }
}