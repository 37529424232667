import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { userReducer } from './user.reducer';
import { customerReducer } from './customer.reducer';
import { orderReducer } from './order.reducer';
import { brandReducer } from './brand.reducer';
import { serviceReducer } from './service.reducer';
import { formSettingReducer } from './form.settings.reducer';

export default combineReducers({
    auth: authReducer,
    users: userReducer,
    customers: customerReducer,
    orders: orderReducer,
    brands: brandReducer,
    services: serviceReducer,
    form_settings:formSettingReducer
});
